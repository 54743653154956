import { useFetchMachine } from 'services/useFetchMachine'
import React, { useEffect, useMemo } from 'react'
import { Select } from 'components/Select'
import { declOfNum } from 'utils/declOfNum'

export const LevelSelect = (
  { levelId, parentId, value, onChange, isDisabled, ...props }
) => {
  const { fetchMachine, isLoadingFetchMachine, dataMachine } = useFetchMachine()

  const options = useMemo(() => (
    dataMachine?.rows?.map(i => ({ label: i.name, value: i.id }))
  ), [dataMachine])

  const countOptionsLabel = options?.length >= 1 ? (
    `${options?.length} ${declOfNum(['опция', 'опции', 'опций'], options?.length)}`
  ) : 'Нет опций'

  const getData = async () => {
    await fetchMachine({
      level: levelId,
      ...parentId && { parentId }
    })
  }

  const handleChange = (e) => {
    onChange({ levelId, option: e})
  }

  useEffect(() => {
    getData()
  }, [parentId])

  return (
    <Select
      options={options}
      isLoading={isLoadingFetchMachine}
      isDisabled={isLoadingFetchMachine || isDisabled}
      placeholder={isDisabled ? 'Недоступно' : countOptionsLabel}
      onChange={handleChange}
      value={value?.id}
      {...props}
    />
  )
}
